<template>
    <el-cascader
            clearable
            class="selectWidth"
            :size="$getComponentSize()"
            :options="options"
            :props="props"
            v-model="inputValue"
            :placeholder="placeholder"
            :disabled="disabled"
            @change="doChange"
            :key="keyValue">
    </el-cascader>
</template>
<script type="text/javascript">
export default {
    name: 'nh-cascader',
    computed: {
        inputValue: {
            get: function () {
                return this.value
            },
            set: function (val) {
                this.$emit('input', val)
            }
        }
    },
    data () {
        return {
            keyValue: 1
        }
    },
    props: {
        value: {
            type: Array,
            default: () => {
                return []
            }
        },
        options: {
            type: Array,
            default: () => {
                return []
            }
        },
        props: {
            type: Object
        },
        size: {
            type: String
        },
        placeholder: {
            type: String
        },
        disabled: {
            type: Boolean
        }
    },
    watch: {
        options() {
            this.keyValue ++
        }
    },
    methods:{
        doChange(e) {
            this.$emit('change', e)
        }
    }
}
</script>
<style type="text/css" lang="scss">
.selectWidth {
  width: 100%;
}
</style>