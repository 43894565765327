<!-- 
三列 
 -->
<template>
	<div class="sdp-select">
        <nh-cascader :props="cascaderProps" :options="options" v-model="inputValue" :placeholder="placeholder"
                     :disabled="property && (property.ro || (property.propertyData && property.propertyData.immutable))" @change="doChange"></nh-cascader>
	</div>
</template>

<script>
	import StringUtil from "@/common/utils/StringUtil";
    import NhCascader from "@/components/patient/components/nh-cascader";
	export default {
		name: '',
		components: {NhCascader},
		data() {
			return {
                options: [],
                cascaderProps: {
                    multiple: this.multiple,
                    label: 'label',
                    value: 'name'
                }
			}
		},
		props: {
			sourceKey: {
				type: String,
				required: true
			},
            value: {
				required: false
			},
            multiple: {
                type: Boolean,
                required: false,
                default: false
            },
            property: {
                type: Object,
                required: false
            },
            placeholder: {
                type: String
            }
		},
        computed: {
            inputValue: {
                get: function () {
                    let valToRtn = undefined
                    if (this.value) {
                        valToRtn = []
                        if (this.multiple) {
                            for (const v of this.value) {
                                valToRtn.push(v.split(','))
                            }
                        }else {
                            valToRtn = this.value.split(',')
                        }
                    }
                    console.log(valToRtn)
                    return valToRtn
                },
                set: function (val) {
                    console.log(val)
                    let valToEmit = undefined
                    if (this.multiple) {
                        valToEmit = []
                        for (const v of val) {
                            valToEmit.push(v.join())
                        }
                    }else {
                        valToEmit = val.join()
                    }
                    this.$emit('input', valToEmit)
                }
            }
        },
		created() {
            if ('area' === this.sourceKey) {
                this.fetchDataFromNH()
            }else if ('hospital' === this.sourceKey || 'hospitalAll' === this.sourceKey) {
                this.fetchData()
            }else {
                this.fetchSourceByUri({})
            }

            console.log('in selectDatasourcePicker')
            if (this.property) {
                this.$globalEvent.$on('selectChanged', (property) => {
                    // console.log('selectChanged on')
                    console.log(this.sourceKey)
                    if(this.property.selectDatasourceRelateProperty
                        && this.property.selectDatasourceRelateProperty === property.id) {
                        setTimeout(()=>{
                            this.fetchSourceByUri(property)
                        },100)
                    }
                })
            }
		},
		mounted() {},
		methods: {
            fetchSourceByUri(sourceProperty) {
                const params = {
                    source: sourceProperty,
                    target: this.property
                }
                this.$httpUtil.post(decodeURIComponent(this.sourceKey), params, res => {
                    this.options = this.processOptions(JSON.parse(res.data))
                }, this)
            },
            fetchDataFromNH() {
                this.$httpUtil.get(this.$urlConstant.nuohua.areaListForSelect,(res)=>{
                    this.options = this.processOptions(res.data)
                },this)
            },
			fetchData() {
				this.$httpUtil.get(StringUtil.stringInject(this.$urlConstant.survey.surveySelectSource, [
					'',
					this.sourceKey
				]),(res)=>{
                    this.options = this.processOptions(JSON.parse(res.data))
                }, this)
			},
            processOptions(options) {
                if (options) {
                    options.forEach(option=>{
                        this.processOption(option)
                        this.processOptions(option.children)
                    })
                }
                return options
            },
            processOption(option) {
                if (option.status) {
                    option.label = option.name + (option.status === 2 ? '(已移除)':'')
                }else {
                    option.label = option.name
                }
            },
            doChange(e) {
                this.$emit('change', e)
            }
		}
	}
</script>

<style scoped>
.sdp-select{
    width: 100%;
}
.sdp-select >>> .el-cascader {
    /*height: 30px;*/
    line-height: 30px;
}
.sdp-select >>> .el-select {
    width: 100%;
}
.sdp-select >>> .el-input__inner {
    border: unset;
    height: 30px;
}
</style>
