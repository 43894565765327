<template>
<div>
    <nh-title title="任务管理"></nh-title>
    <div style="display: flex">
        <div><nh-menu active-selected="TaskList"></nh-menu></div>
        <div class="list-container">
            <div class="search-bar-wrapper">
                <div class="search-input-wrapper">
                    <div class="search-bar">
                        <el-input class="search-input" v-model="searchParams.searchKey"
                                  @keyup.enter.native="doSearch" placeholder="查找" clearable/>
                    </div>
                    <div class="search-button">
                        <el-image
                                src="https://yitian-cms.oss-cn-hangzhou.aliyuncs.com/img/2ec27f9034285ffd9cf33f2a19dfb4af.png"
                                mode="aspectFit" @click="doSearch"></el-image>
                    </div>
                </div>
            </div>
            <el-table class="list-table-container"
                    :data="taskList"
                    @row-click="doRowClick"
                      :row-class-name="getRowClassName"
                    stripe>
                <el-table-column
                        label="编号">
                    <template slot-scope="scope">
                        {{scope.row.patient.number2?scope.row.patient.number2:scope.row.patient.number}}
                    </template>
                </el-table-column>
                <el-table-column
                        label="序号">
                    <template slot-scope="scope">
                        {{ getColumnValue(scope.row.patient.rzSurvey, 'xh') }}
                    </template>
                </el-table-column>
                <el-table-column
                        label="姓名">
                    <template slot-scope="scope">
                        {{ getColumnValue(scope.row.patient.rzSurvey, 'name') }}
                    </template>
                </el-table-column>
                <el-table-column
                        label="事件">
                    <template slot-scope="scope">
                        {{getTaskTitleName(scope.row)}}
                    </template>
                </el-table-column>
                <el-table-column
                        width="120"
                        label="医院">
                    <template slot="header" slot-scope="scope">
                        <SelectDatasourcePicker :aa="scope" :sourceKey="'hospital'" v-model="searchParams.hospital"
                                                placeholder="医院" @change="doSearch"></SelectDatasourcePicker>
                    </template>
                    <template slot-scope="scope">
                        {{getSubHospital(getColumnValue(scope.row.patient.rzSurvey, 'hospital'))}}
                    </template>
                </el-table-column>
                <el-table-column
                        label="适应症">
                    <template slot="header" slot-scope="scope">
                        <el-select :aa="scope" v-model="searchParams.syz" clearable placeholder="适应症"
                                   @change="doSearch" class="select-header">
                            <el-option v-for="item in jcjbzdList" :key="item.id" :label="item.name"
                                       :value="item.id"></el-option>
                        </el-select>
                    </template>
                    <template slot-scope="scope">
                        {{getColumnValue(scope.row.patient.rzSurvey, 'syz')}}
                    </template>
                </el-table-column>
                <el-table-column
                        label="入组日期">
                    <template slot="header" slot-scope="scope">
                        <el-date-picker :aa="scope"
                                        v-model="searchParams.rzrq"
                                        type="daterange"
                                        class="rzrq-header"
                                        @change="doSearch"
                                        value-format="yyyy-MM-dd"
                                        range-separator="-"
                                        start-placeholder="入组开始日期"
                                        end-placeholder="入组结束日期">
                        </el-date-picker>
                    </template>
                    <template slot-scope="scope">
                        {{getColumnValue(scope.row.patient.rzSurvey, 'rzrq')}}
                    </template>
                </el-table-column>
                <el-table-column
                        prop="patient.rzfs"
                        align="center"
                        label="入组方式">
                    <template slot="header" slot-scope="scope">
                        <el-select :aa="scope" v-model="searchParams.rzfs" clearable placeholder="入组方式"
                                   @change="doSearch" class="select-header">
                            <el-option v-for="item in rzfsList" :key="item" :label="item"
                                       :value="item"></el-option>
                        </el-select>
                    </template>
                </el-table-column>
                <el-table-column
                        label="患者状态">
                    <template slot="header" slot-scope="scope">
                        <el-select :aa="scope" v-model="searchParams.hzzt" clearable placeholder="患者状态"
                                   @change="doSearch" class="select-header">
                            <el-option v-for="item in hzztList" :key="item.id" :label="item.name"
                                       :value="item.id"></el-option>
                        </el-select>
                    </template>
                    <template slot-scope="scope">
                        <el-tooltip v-if="scope.row.patient.hzzt === '5'" effect="dark" :content="scope.row.patient.hzztError" placement="top-start">
                            <div>{{ getHzzt(scope.row.patient) }}</div>
                        </el-tooltip>
                        <div v-else>{{ getHzzt(scope.row.patient) }}</div>

                    </template>
                </el-table-column>
                <el-table-column
                        label="dot">
                    <template slot="header" slot-scope="scope">
                        <el-select :aa="scope" v-model="searchParams.dot" clearable placeholder="dot"
                                   @change="doSearch" class="select-header">
                            <el-option v-for="item in dotList" :key="item" :label="item"
                                       :value="item"></el-option>
                        </el-select>
                    </template>
                    <template slot-scope="scope">
                        {{ scope.row.patient.dot }}
                    </template>
                </el-table-column>
                <el-table-column
                        label="查看病历">
                    <template slot-scope="scope">
                        <el-image v-if="isTaskEnd(scope.row)" @click.stop="goPatient(scope.row)" class="tl-eye" fit="contain" src="https://yitian-cms.oss-cn-hangzhou.aliyuncs.com/img/5730ae7fde65148c742d8875d3a9f313.png"></el-image>
                        <el-image v-else @click.stop="goPatient(scope.row)" class="tl-eye" fit="contain" src="https://yitian-cms.oss-cn-hangzhou.aliyuncs.com/img/afdc9d6f7e4f13b917d74e947d469171.png"></el-image>
                    </template>
                </el-table-column>
                <el-table-column
                        label="任务时间" width="180">
                    <template slot-scope="scope">
                        {{scope.row.taskStartDate | formatDate2}}~~{{dateUtil.addSecond(scope.row.taskEndDate, -60) | formatDate2}}
                    </template>
                </el-table-column>
            </el-table>
            <div style="text-align: right;">
                <el-pagination
                        layout="total,prev,pager,next"
                        :total="total"
                        :page-size="$urlConstant.pageSize"
                        :current-page="currentPage"
                        @current-change="fetchData">
                </el-pagination>
            </div>
        </div>
    </div>
</div>
</template>

<script>
import NhTitle from "@/components/patient/components/nh-title";
import NhMenu from "@/components/patient/components/nh-menu";
import StringUtil from "@/common/utils/StringUtil";
import surveyUtil from "@/common/surveyUtil";
import {
    // mapActions,
    mapState,
    mapMutations,
    // mapGetters
} from 'vuex'
import SelectDatasourcePicker from '@/components/survey/components/properties/singleSelect/SelectDatasourcePicker'
import rzConstant from "@/common/rzConstant";
import * as dateUtil from "@/common/utils/dateUtil";
let fromName = undefined
export default {
    name: "TaskList",
    components: {NhMenu, NhTitle,SelectDatasourcePicker},
    data() {
        return {
            taskList: [],
            currentPage: 1,
            total: 0,
            totalPages: 0,
            searchParams: {
                finished: false
            },
            jcjbzdList: [],
            hzztList: rzConstant.hzzt,
            dotList:[],
            rzfsList: ['线下', '远程'],
        }
    },
    computed: {
        dateUtil() {
            return dateUtil
        },
        ...mapState('task',[
            'searchQuery'
        ]),
    },
    // 保留状态的情况:文章编辑
    beforeRouteEnter(to, from, next) {
        fromName = from.name
        next();
    },
    watch: {},
    created() {
        if (fromName !== "Patient" && fromName !== "TaskSurvey") {
            this.cleanSearchQuery()
        }
        this.searchParams = JSON.parse(JSON.stringify(this.searchQuery))
        this.currentPage = this.searchParams.currentPage
        this.fetchData(this.currentPage)
        this.fetchSurveyInfos()
        this.fetchDots()
    },
    methods: {
        ...mapMutations('task',[
            'storeSearchQuery',
            'cleanSearchQuery'
        ]),
        fetchData(index) {
            this.searchParams.currentPage = index
            this.storeSearchQuery(this.searchParams)

            this.$httpUtil.post(StringUtil.stringInject(this.$urlConstant.nuohua.taskList, [
                    index - 1,
                    this.$urlConstant.pageSize
                ]),

                this.searchParams,
                (responseData) => {
                    this.taskList = responseData.data.content
                    this.total = responseData.data.totalElements
                    this.totalPages = responseData.data.totalPages
                    this.currentPage = index
                },
                this
            )
        },
        fetchSurveyInfos() {
            this.$httpUtil.get(this.$urlConstant.nuohua.defaultSurveyList, (res) => {
                const rzSurvey = res.data.rz
                // const sfSurvey = res.data.sf
                // this.yypcList = surveyUtil.findPropertyById(rzSurvey, 'yypc').options
                // this.yyfaList = surveyUtil.findPropertyById(rzSurvey, 'yyfa').options
                this.jcjbzdList = surveyUtil.findPropertyById(rzSurvey, 'syz').options
                // this.rzztList = surveyUtil.findPropertyById(rzSurvey, 'rzfs').options
            }, this)
        },
        fetchDots() {
            this.$httpUtil.get(this.$urlConstant.nuohua.dotList, (res) => {
                this.dotList = res.data
                this.dotList.sort(function(a, b) {
                    return a - b;
                });
            }, this)
        },
        doRowClick(task) {
            console.log(task)
            const taskTitle = this.getTaskTitleName(task)
            this.$router.push({name:'TaskSurvey',query: {
                    patientId: task.patientId,
                    survey: task.surveyId,
                    'survey-data': task.surveyDataId,
                    taskId: task.taskId,
                    ownerUserId: task.patientUserId,
                    redirect: 'TaskList',
                    taskTitle: taskTitle
                }})
        },
        getTaskTitleName(task) {
            if(task.indexInPatient && task.indexInPatient > 0 && task.name !== '远程入组') {
                return '第' + task.indexInPatient + '次' + task.name
            }
            return task.name
        },
        goPatient(task) {
            this.$router.push({
                name: 'Patient',
                query: {id: task.patientId}
            })
        },
        isTaskEnd(task) {
            if (task.taskEndDate) {
                return new Date(task.taskEndDate).getTime() < new Date().getTime()
            }
            return false
        },
        getRowClassName(row) {
            console.log(row)
            const task = row.row
            return this.isTaskEnd(task)?'task-row-end':''
        },
        getColumnValue(survey, propertyId) {
            return surveyUtil.findPropertyValueById(survey, propertyId)
        },
        getSubHospital(hospital) {
            if (hospital){
                const h = hospital.split(',')
                if (h.length > 2)
                    return h[2]
            }
            return hospital
        },
        doSearch() {
            this.fetchData(1)
        },
        getHzzt(patient) {
            const hzzt = patient.hzzt
            const hzztObj = rzConstant.hzzt.find(obj=>obj.id === hzzt)
            if (hzztObj)
                return hzztObj.name
            return hzzt
        }
    },
}
</script>

<style scoped>
.tl-eye {
    width: 30px;
    height: 30px;
    cursor: pointer;
}
.task-cell-end {
    font-family: PingFangSC-Regular;
    font-size: 12px;
    color: #8A99BD;
    letter-spacing: -0.36px;
}
.search-bar-wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 10px;
    justify-content: space-between;
}
.search-input-wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.search-bar {
    width: 300px;
    margin-left: 5px;
    height: 30px;
}

.search-input {
    background: #FFFFFF;
    border-radius: 8px 0 0 8px;
    height: 100%;
    padding-left: 5px;
}

.search-input >>> .el-input__inner {
    height: 30px;
    border: unset;
}

.search-button {
    width: 30px;
    height: 30px;
    cursor: pointer;
}

.search-button >>> .el-image {
    width: 30px;
    height: 30px;
}
.rzrq-header {
    width: 200px;
    height: 32px;
}

.rzrq-header >>> .el-range-input {
    font-size: 10px;
}

.select-header >>> .el-input__inner {
    font-size: 10px;
    height: 32px;
}
</style>